import React, { ReactElement, FC } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Container from "../components/container/container"
import HeroBasic from "../components/hero-basic/hero-basic"
import BasicContent from "../components/basic-content/basic-content"
import SEO from "../components/seo/seo"
import CookiesBanner from "../components/cookies-banner/cookies-banner"
import CountryLanguageBanner from "../components/country-language-banner/country-language-banner"
import {
  I_CountryLanguage,
  I_Navigation,
  I_Footer,
  I_CookiesBanner,
  I_SEO,
  I_Location,
} from "../global/interfaces"

interface I_Strapi {
  countryLanguage: I_CountryLanguage
  navigation: I_Navigation
  footer: I_Footer
  cookiesBanner: I_CookiesBanner
}

interface I_PageContext {
  title: string
  text: string
  seo: I_SEO
  baseUrl: string
}

interface I_Props {
  pageContext: I_PageContext
  data: {
    strapi: I_Strapi
  }
  location: I_Location
}

const Basic: FC<I_Props> = ({
  pageContext,
  data,
  location,
}: I_Props): ReactElement => {
  const { countryLanguage, navigation, footer, cookiesBanner } = data.strapi
  const { title, text, baseUrl } = pageContext

  return (
    <>
      <SEO {...pageContext.seo} baseUrl={baseUrl} />
      <CookiesBanner {...cookiesBanner} />
      <CountryLanguageBanner {...countryLanguage} baseUrl={baseUrl} />
      <Layout
        navigation={navigation}
        footer={footer}
        location={location}
        baseUrl={baseUrl}
      >
        <Container>
          <HeroBasic title={title} />
          <BasicContent content={text} />
        </Container>
      </Layout>
    </>
  )
}

export default Basic

export const pageQuery = graphql`
  query BasicPageQuery($locale: String) {
    strapi {
      countryLanguage(locale: $locale) {
        text
        countryOptions {
          label
          options {
            id
            text
            value
          }
        }
        languageOptions {
          label
          options {
            id
            text
            value
          }
        }
        button {
          text
        }
      }
      navigation(locale: $locale) {
        links {
          id
          link
          target
          text
        }
      }
      footer(locale: $locale) {
        copyright
        linkList {
          id
          title
          links {
            id
            text
            link
            target
          }
        }
      }
      cookiesBanner(locale: $locale) {
        title
        text
        acceptButton {
          text
        }
      }
    }
  }
`
