import React, { ReactElement, FC } from "react"
import { Row, Col, Typography } from "antd"

import "./hero-basic.less"

const { Title } = Typography

interface I_Props {
  title: string
}

const HeroBasicComponent: FC<I_Props> = ({ title }: I_Props): ReactElement => {
  return (
    <Row justify="center" className="hero-basic">
      <Col xs={24} lg={18}>
        <Title className="hero-basic__title">{title}</Title>
      </Col>
    </Row>
  )
}

export default HeroBasicComponent
